<template>
    <div class="feature-container">
        <h2> خدمات ما </h2>
        <div class="services">
            <div class="service">
                <div class="title">
                    <img src="../../assets/Icons/cardWallet.svg" alt="">
                    <span> کیف پول اختصاصی </span>
                </div>
                <p> 
                    لورم ایپسوم یه متن تصادفی برای نشون دادن در این مکان به زودی <br />
                    تکست مناسبی قرار خواهد گرفت
                </p>
            </div>
            <div class="service">
                <div class="title">
                    <img src="../../assets/Icons/cardTrade.svg" alt="">
                    <span> معامله امن و آسان </span>
                </div>
                <p> 
                    لورم ایپسوم یه متن تصادفی برای نشون دادن در این مکان به زودی <br />
                    تکست مناسبی قرار خواهد گرفت
                </p>
            </div>
            <div class="service">
                <div class="title">
                    <img src="../../assets/Icons/cardPannel.svg" alt="">
                    <span> پنل کاربری پیشرفته </span>
                </div>
                <p> 
                    لورم ایپسوم یه متن تصادفی برای نشون دادن در این مکان به زودی <br />
                    تکست مناسبی قرار خواهد گرفت
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name:'Feature',

    }
</script>

<style lang="scss" scoped>
.service{
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    padding:24px;
    transition: 300ms;
    .title{
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 12px;
    }
    &:hover{
        box-shadow: 0px 0px 8px -1px rgba(65, 70, 192, 0.2);
        border-radius: 12px;
    }
}
.services{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.feature-container{
    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: 60px;
    padding: 40px 120px;
}
@media only screen and(max-width:1200px){
    .feature-container{
        padding: 20px;
    }
    .services{
        flex-direction: column;
        row-gap: 20px;
        align-items: center;

    }
}
</style>