<template>
    <div class="homepage-container">
        <Banner />
        <Carusel :coinsToman="coinsToman" :coinsTether="coinsTether" />
        <Market :coinsToman="coinsToman" :coinsTether="coinsTether" />
        <Status :key="info.totaltrad" :info="info" />
        <Feature />
    </div>
</template>

<script>
import Banner from './Banner.vue'
import Carusel from './Carusel2.vue'
import Market from './Market.vue'
import Status from './Status.vue'
import Feature from './Feature.vue';
    export default {
        name:'HomePage',
        components:{
            Banner,
                Carusel,
                Market,
                Status,
                Feature
        },
        data() {
            return {
                info:{},
                TomanCoin: 'market_type=BITCOIN_TOMAN' +
                  '&market_type=BITCOIN_CASH_TOMAN' +
                  '&market_type=ETHEREUM_TOMAN' +
                  '&market_type=LITE_COIN_TOMAN' +
                  '&market_type=BINANCE_COIN_BSC_TOMAN' +
                  
                  '&market_type=STELLAR_TOMAN' +
                  '&market_type=TRON_TOMAN' +
                  '&market_type=CELO_TOMAN' +
                  '&market_type=RIPPLE_TOMAN' +
                  '&market_type=DOGE_COIN_TOMAN' +
                  '&market_type=MATIC_TOMAN' +
                  '&market_type=TETHER_TOMAN',



              TetherCoin: 'market_type=BITCOIN_TETHER' +
                  '&market_type=BITCOIN_CASH_TETHER' +
                  '&market_type=ETHEREUM_TETHER' +
                  '&market_type=LITE_COIN_TETHER' +
                  '&market_type=BINANCE_COIN_BSC_TETHER' +
                  '&market_type=MATIC_TETHER' +

                  '&market_type=STELLAR_TETHER' +
                  '&market_type=TRON_TETHER' +
                  '&market_type=RIPPLE_TETHER' +
                  '&market_type=DOGE_COIN_TETHER' +
                  '&market_type=CELO_TETHER', 






                coinsToman:[],
                coinsTether:[],
                pricetype:'TOMAN',
            }
        },
        methods:{
            async setData() {
                    this.state.loading = true
                    const res = await this.$axios.get('/trades/market-info?' + (this.TomanCoin),{
                        params: {
                            weeklyDataIncluded: true,
                        }
                    })
                    const res1 = await this.$axios.get('/trades/market-info?' + (this.TetherCoin),{
                        params: {
                            weeklyDataIncluded: true,
                        }
                    })
                    this.coinsToman = res.content
                    this.coinsTether = res1.content
                    // console.log('week',this.coin);
            },
            async getCoins() {
                    this.state.loading = true
                    const res = await this.$axios.get('/trades/market-info',{
                        params: {
                            weeklyDataIncluded: true,
                        }
                    })
                    this.coinsToman = res.content.filter(a => a.marketType.includes('TOMAN'))
                    this.coinsTether = res.content.filter(a => a.marketType.includes('TETHER'))
            },
            async getInfo() {
                const res = await this.$axios('trades/market-info-home')
                this.info.userscount = res.baseDTO.userCount
                this.info.tradprice = res.baseDTO.tradesValue
                this.info.totaltrad = res.baseDTO.tradesAmount
            },
        },
        async mounted(){
            await this.getInfo();
            this.getCoins()
        }
    }
</script>

<style lang="scss" scoped>
.homepage-container{
    display: flex;
    flex-direction: column;
    background: var(--homepage-bg);
    position: relative;
    &::after{
        content: ' ';
        background: var(--red);
        filter: blur(400px);
        opacity: .1;
        filter: drop-shadow(0 0 0.75rem var(--red));
        box-shadow: 0px 0px 100px 100px var(--red);
        background-size:cover;
        background-repeat: repeat-x;
        position: absolute;
        height: 200px;
        border-radius: 50%;
        width: 10%;
        left: 0;
        top: 1000px;
    }
}
@media only screen and(max-width:1200px){
    .homepage-container::after{
        content: none !important;
    }   
    .homepage-container::before{
        content: none !important;
    }   
}
</style>