<template>
  <div class="carousel">
<Hooper :settings="hoopersSetting">

<Slide v-for="(coin,index) in coinsTether" :key="index" class="hooper-item">
  <MarketChartApex :data="coin.lastWeekData" :option="coin.last24HourChange > 0 ? true : false" class="chart"/>
                    <div class="coin-info">
                        <div class="coin-label">
                            <div class="coin-icon">
                                <img :src="'/coins/'+$coin2Snake[coin.relatedCoin]+'.png'" alt="">
                                <div class="coinunit">
                                    <span class="faname"> {{$coinLabel[coin.relatedCoin]}} </span>
                                    <span class="unit"> {{coin.relatedCoin}} </span>
                                </div>
                            </div>
                            <div class="coinchange">
                                <span v-if="coin.last24HourChange > 0" class="success"> 
                                    {{$toLocal(coin.last24HourChange,2)}}% 
                                    <inline-svg class="success rotate" :src="require('../../assets/Icons/arrow.svg')" />
                                </span>
                                <span v-if="coin.last24HourChange < 0" class="fail"> 
                                    {{$toLocal(Math.abs(coin.last24HourChange),2)}}% 
                                    <inline-svg class="fail" :src="require('../../assets/Icons/arrow.svg')" />
                                </span>
                                <span class="changetime"> (24h) </span>
                            </div>
                        </div>
                        <div class="price">
                            <div v-if="coin.relatedCoin !== 'USDT'" :class="coin.last24HourChange > 0 ? 'success':'fail'" class="tetherprice"> {{$toLocal(coin.lastPrice,2)}} تتر</div>
                            <span :class="coin.last24HourChange > 0 ? 'success':'fail'" class="tomanprice"> {{$toLocal(coinsToman[index].lastPrice,0)}} تومان </span>
                        </div>
                    </div>
</Slide>




<hooper-navigation slot="hooper-addons"></hooper-navigation>
</Hooper>
  </div>
</template>

<script>
import MarketChartApex from '../Tools/MarketChartApex.vue'
import {Hooper, Slide,Navigation as HooperNavigation} from 'hooper'
import 'hooper/dist/hooper.css';
export default {
  name: "CoinsCarousel",
  props: ['coinsToman', 'coinsTether'],
  components: {
    MarketChartApex,
    Hooper,
    Slide,
    HooperNavigation
  },
  data() {
    return {
      hoopersSetting:{

        rtl:true,
        infiniteScroll:true,
        autoPlay:false,
        autoplaySpeed: 2000,
        itemsToShow: 4,
        centerMode:true,
        breakpoints : {
          1200:{
            itemsToShow: 4,
            centerMode:true,
          },
          800:{
            itemsToShow: 3,
            centerMode:true,
          },
          500:{
            itemsToShow:2,

          },
          320:{
            itemsToShow:1,
            centerMode:true,
          }
        }
      },
    //   responsive: [
    //     {
    //       "breakpoint": 1200,
    //       "settings": {
    //         "slidesToShow": 2,
    //         "slidesToScroll": 1,
    //         "infinite": true,
    //         "dots": false
    //       }
    //     },
    //     {
    //       "breakpoint": 992,
    //       "settings": {
    //         "slidesToShow": 2,
    //         "slidesToScroll": 1,
    //         "infinite": true,
    //         "dots": false,
    //         "arrow": false
    //       }
    //     },
    //     {
    //       "breakpoint": 600,
    //       "settings": {
    //         "slidesToShow": 1,
    //         "slidesToScroll": 1,
    //         "initialSlide": 2,
    //         "arrows": false
    //       }
    //     },
    //     {
    //       "breakpoint": 480,
    //       "settings": {
    //         "slidesToShow": 1,
    //         "slidesToScroll": 1,
    //         "initialSlide": 1,
    //         // "centerMode": true,
    //         "arrows": false
    //       }
    //     }
    //   ],

      settings: {
        rtl: true,
        arrows: true,
        autoplay: true,
        dots: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplaySpeed: 600,
        useCSS: true,
        // centerMode:true,
      }
    }
  },
}
</script>

<style lang="scss" scoped>
// @media only screen and(max-width:1200px){
//     .carousel{
//         width: 100% !important;
//     }
// }
.sidearrow{
    width: 80px;
    height: 80px;
    cursor: pointer;
}
h2{
    text-align: start;
    align-self: flex-start;
}
.coin-info{
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    width: 100%;
    height: 100%;
    .coin-label{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .coin-icon{
            display: flex;
            flex-direction: row;
            column-gap: 3px;
            img{
                width: 40px;
                height: 40px;
            }
            .coinunit{
                display: flex;
                flex-direction: column;
                // row-gap: px;
                .unit{
                    font-weight: 400;
                    font-size: clamp(10px,1vw,12px);
                    color: var(--a-hover);
                }
            }
        }
        .coinchange{
            display: flex;
            flex-direction: column;
            // align-items: center;
            // column-gap: 8px;
            .changetime{
                align-self: flex-end;
                font-weight: 400;
                font-size: clamp(10px,1vw,12px);
                color: rgba(132, 110, 110, 1);
            }
            .change{
                display: flex;
                flex-direction: row;
                column-gap: 3px;
                font-weight: 400;
                font-size: clamp(14px,2vw,16px);
            }
        }
    }
    .price{
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 5px;
        align-self: flex-end;
        .tetherprice{
            font-weight: 700;
            font-size: clamp(16px,2vw,20px);
            text-align: end;
        }
        .tomanprice{
            font-weight: 400;
            font-size: clamp(10px,1vw,12px);
            text-align: end;
        }
    }
}
.hooper-item{
    // width: 20% !important;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding:24px;
    background: #F0F0F5;
    box-shadow: 0px 0px 8px -1px rgba(65, 70, 192, 0.2);
    border-radius: 12px;
    transition: 700ms;
    &:hover{
        box-shadow: 0px 0px 14px -1px rgba(65, 70, 192, 0.47);
    }
    .chart{
        position: absolute;
        opacity: .5;
        height: 100%;
        width: 100%;
    }
}
.carusel-container{
    z-index: 3;
    position: relative;
    padding: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 48px;
    align-items: center;
    justify-content: center;
    padding:40px 120px;
    .items-container{
        display: flex;
        flex-direction: row;
        width: 100%;
        padding: 10px 5px;
        align-items: center;
        column-gap: 20px;
        overflow: scroll;
        // padding: 20px 0;
        .chart{
            position: absolute;
            height: 50%;
            bottom: 15px;
            right: 0;
            width: 100%;
            // padding:0 20px;
        }
    }
}
@media only screen and(max-width:1000px){
    .carusel-container{
        padding: 20px !important;
    }
}
@media only screen and(max-width:500px){
        .sidearrow{
            display: none;
        }
}
.carousel {
  text-align: center;
  width: 100%;
  align-self: center;
}

.card {
  box-shadow: -1px 4px 8px -1px rgba(192, 222, 234, 0.2);
  border-radius: 8px;
  max-width: 248px;
  height: 147px;
  padding: 28px;
}

.price {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  align-self: center;

  .tetherprice {
    font-weight: 700;
    font-size: clamp(16px, 2vw, 20px);
    text-align: center;
    background: none !important;
  }

  .tomanprice {
    font-weight: 400;
    font-size: clamp(10px, 1vw, 12px);
    text-align: center;
    background: none !important;
  }
}

.chart {
  height: 40px;
  width: 35%;
}
</style>

<style lang="scss">
//.slick-slider {
//  display: flex !important;
//  justify-content: center !important;
//}
//
//.slick-list {
//  width: 80% !important;
//  padding: 20px 0 !important;
//}

.slick-list {

  height: 160px !important;
}


@media(max-width: 450px){
  .card{
    max-width: unset;
    width: 100%;
    margin: 0 auto;
  }
}
</style>