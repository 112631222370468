<template>
    <div class="status-container">
        <div class="img-container">
                <img class="banner-pic" src="../../assets/coinsPic.png" alt="">
        </div>
        <div class="left-row">
            <div class="texts">
                <h2> ویستا </h2>
                <p>
                    لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم<br />
                    از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها<br/>
                    و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است،<br />
                </p>
            </div>
            <div class="status">
                    <div class="item">
                        <span class="numbers"> {{$toLocal(info.totaltrad)}} </span>
                        <div class="subject">
                            <img src="../../assets/Icons/done.svg" alt="">
                            <span> معامله انجام شده </span>
                        </div>
                    </div>
                    <div class="item">
                        <span class="numbers"> {{$toLocal(info.tradprice,0)}} </span>
                        <div class="subject">
                            <img src="../../assets/Icons/tradeAmount.svg" alt="">
                            <span> حجم معاملات (تومان) </span>
                        </div>
                    </div>
                    <div style="border:none;" class="item">
                        <span class="numbers"> {{$toLocal(info.userscount)}} </span>
                        <div class="subject">
                            <img src="../../assets/Icons/activeUsers.svg" alt="">
                            <span> کاربران فعال </span>
                        </div>
                    </div>
                </div>
        </div>    
    </div>
</template>

<script>
    export default {
        name : 'Status',
        props:['info'],
        
    }
</script>

<style lang="scss" scoped>
.banner-pic{
    max-height: 500px;
}
.left-row{
    display: flex;
    flex-direction: column;
    row-gap: 42px;
}
.item{
    height: 100%;
    max-height: 83px;
    display: flex;
    flex-direction: column;
    // row-gap: 40px;      
    padding:0  18px;
    border-left: 1px solid #FACD23;
    .numbers{
        color: var(--primary-dark);
        font-weight: 700;
        font-size: clamp(22px,3vw,28px);
    }
    .subject{
        display: flex;
        flex-direction: row;
        column-gap: 10px;
    }
}
.status{
    display: flex;
    flex-direction: row;
    column-gap: 20px;
}
.texts{
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    height: 100%;
}
.status-container{
    padding: 40px 120px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    position: relative;
    &::before{
        content: ' ';
        background: var(--red);
        filter: blur(400px);
        opacity: .1;
        filter: drop-shadow(0 0 0.75rem var(--red));
        box-shadow: 0px 0px 100px 100px var(--red);
        background-size:cover;
        background-repeat: repeat-x;
        position: absolute;
        height: 300px;
        border-radius: 50%;
        width: 10%;
        right: 300px;
        top: 100px;
    }
}
@media only screen and(max-width:1000px){
    .img-container{
        display: none;
    }
    .status{
        flex-direction: column;
        row-gap: 20px;
    }
    .item{
        border-left: 1px solid #FACD23 !important;
    }
    .status-container{
        padding: 20px;
        &::before{
            content: unset !important;
        }
    }
}
</style>