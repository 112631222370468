<template>
    <div class="banner-container">
        <div class="title">
            <h1> کندل </h1>
            <p> لورم ایپسوم متن تصادفیه که میذاریم برای نشون دادن <br />
                اینکه اینجا یه تکست قرار میگیره
            </p>
            <div class="btns">
                <button @click.prevent="$router.push('/advancetrade')" class="submit"> شروع معامله </button>
                <button @click.prevent="$router.push('/market')"> قیمت ارز های دیجیتال </button>
            </div>
        </div>
        <img class="banner-img" src="../../assets/Banner.png" alt="">
    </div>
</template>

<script>
    export default {
        name:'Banner'
    }
</script>

<style lang="scss" scoped>
.banner-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    padding-right: 120px;
}
.title{
    display: flex;
    flex-direction: column;
    row-gap: 32px;
    h1{
        font-size: clamp(36px,3vw,44px);
        font-weight: 700;
    }
    span{
        font-size: clamp(20px,3vw,24px);
        font-weight: 500;
    }
    .btns{
        display: flex;
        flex-direction: row;
        width: 100%;
    }
}
@media only screen and(max-width:1350px){
    .banner-container{
        padding-right: 20px !important;
    }
}
@media only screen and(max-width:1200px){
    .banner-container{
        padding-top: 120px !important;
        justify-content: center;
        .title{
            text-align: center;
        }
    }
    .banner-img{
        display: none;
    }
}
</style>